<template>
    <div>
        <v-row v-if="!permissions.can_download">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Weekly Sales Report</p>
                <p class="text-title mb-0">You do not have permission to view weekly sales report</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_download">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-form lazy-validation ref="form" v-model="valid">
                        <v-sheet color="white" elevation="0" class="pa-0">
                            <p class="text-h6 mb-2">Weekly Sales Report</p>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-autocomplete dense label="Projects*" class="my-3" :items="projects" :rules="rules.project" v-model="filters.project"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-select dense label="Report Type*" class="my-3" :items="reportTypes" :menu-props="{top: false, offsetY: true}" :rules="rules.category" v-model="filters.category" @change="changeReportType"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-select dense label="Report Display Type*" class="my-3" :items="displayTypes" :menu-props="{top: false, offsetY: true}" :rules="rules.display" v-model="filters.display"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-if="isWeeklyReport">
                                    <v-dialog persistent width="320px" ref="reportDateDialog" :return-value.sync="filters.report_date" v-model="reportDateDialog">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="Report Date*" class="mt-3" :rules="rules.report_date" :error-messages="errors.report_date" v-on="on" v-bind="attrs" v-model="filters.report_date"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable color="primary" v-model="filters.report_date">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="reportDateDialog=false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.reportDateDialog.save(filters.report_date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-if="isWeeklyReport">
                                    <v-select dense label="Weeks*" class="my-3" :items="weeks" :menu-props="{top: false, offsetY: true}" :rules="rules.weeks" v-model="filters.weeks"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="!isWeeklyReport">
                                    <v-dialog persistent width="320px" ref="reportDatesDialog" :return-value.sync="filters.report_dates" v-model="reportDatesDialog">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="Report Dates*" class="mt-3" v-on="on" v-bind="attrs" :rules="rules.report_dates" :error-messages="errors.report_dates" v-model="filters.report_dates"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable multiple show-current color="primary" v-model="filters.report_dates">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" class="elevation-0" :ripple="false" @click="reportDatesDialog=false">Cancel</v-btn>
                                            <v-btn text color="primary" class="elevation-0" :ripple="false" @click="$refs.reportDatesDialog.save(filters.report_dates)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" order="last" class="text-right">
                                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openDownloadDialog">
                                        <v-icon small left>mdi-download</v-icon> Download
                                    </v-btn>
                                    <v-btn small class="mt-2 elevation-0" :ripple="false" @click='reset'>
                                        <v-icon small left>mdi-undo</v-icon> Reset
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-form>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">Note:<br>
                    1. * Required field<br>
                    2. When report type is daily, 10 single days can be selected<br>
                    3. When report type is weekly, 4 weeks can be selected<br>
                    </small>
                </v-col>
            </v-row>
        </template>
        <download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
    </div>
</template>


<script>

import { stringifyJSON } from '@/utils/encodes'
import SalesDownloadDialog from './SalesDownloadDialog.vue'
import SimpleActionDialog from '../../../../shared/SimpleActionDialog.vue'

export default {
    name: 'SalesTable',
    components: {
        simpleActionDialog: SimpleActionDialog,
        downloadDialog: SalesDownloadDialog,
    },
    data() {
        return {
            valid: true,
            isWeeklyReport: true,
            reportDateDialog: false,
            reportDatesDialog: false,
            items: [],
            projects: [],
            reportTypes: [
                { text: 'Daily', value: 'd' },
                { text: 'Weekly', value: 'w' },
            ],
            displayTypes: [
                { text: 'Simplify', value: 's' },
                { text: 'Full', value: 'f'}
            ],
            weeks: [
                { text: '1 Week', value: '1' },
                { text: '2 Weeks', value: '2' },
                { text: '3 Weeks', value: '3' },
                { text: '4 Weeks', value: '4' },
            ],
            filters: {
                category: 'w',
                weeks: '1',
                report_date: '',
                report_dates: [],
                display: 'f'
            },
            object: {},
            permissions: {
                can_download: false
            },
            rules: {
                project: [
                    (value) => !!value || 'Project is required'
                ],
                category: [
                    (value) => !!value || 'Report type is required'
                ],
                display: [
                    (value) => !!value || 'Display type is required'
                ],
                report_date: [
                    (value) => !!value || 'Report date is required'
                ],
                weeks: [
                    (value) => !!value || 'Weeks is required'
                ],
                report_dates: [
                    (value) => {
                        if(value.length === 0){
                            return 'Report dates is required'
                        }

                        if(value.length > 10){
                            return 'Report dates can maximum select 10 single days'
                        }

                        return true
                    }
                ]
            },
            errors:{}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.$store.dispatch('epanel/report/getReportSalesWeekly').then((response) => {
                this.permissions = response.data.permissions
                this.projects = response.data.projects
            })
        },
        reset: function() {
            this.filters = { project: '', category: 'w', weeks: '1', report_date: '', report_dates: [] }
            this.form.resetValidation()
        },
        changeReportType: function(value) {
            this.isWeeklyReport = value === 'w'
            this.form.resetValidation()

            if(this.isWeeklyReport){
                this.object.report_date = []
                this.object.weeks = "1"
            }else{
                this.object.report_dates = []
            }
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download && this.form.validate()){
                var object = { project: this.filters.project, report_type: this.filters.category, display_type: this.filters.display }
                if(this.isWeeklyReport){
                    object['weeks'] = this.filters.weeks
                    object['report_date'] = this.filters.report_date
                    object['display_type'] = this.filters.display
                }else{
                    object['report_dates'] = stringifyJSON(this.filters.report_dates)
                }

                this.downloadDialog.updateObject(object)
                this.downloadDialog.open()
            }
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        }
    }
}

</script>